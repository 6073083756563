.user-profile.public-user-profile {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.public-user-profile h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.public-user-profile h2 {
  font-size: 20px;
  color: #444;
  margin-top: 20px;
  margin-bottom: 10px;
}

.public-user-profile p {
  margin-bottom: 10px;
  color: #666;
}

.public-user-profile .posts-list,
.public-user-profile .replies-list {
  list-style-type: none;
  padding: 0;
}

.public-user-profile .posts-list li,
.public-user-profile .replies-list li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.public-user-profile .post-date,
.public-user-profile .reply-date {
  font-size: 12px;
  color: #888;
}

.public-user-profile .loading,
.public-user-profile .error,
.public-user-profile .not-found {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  color: #666;
}

.public-user-profile a {
  color: #4a90e2;
  text-decoration: none;
}

.public-user-profile a:hover {
  text-decoration: underline;
}
