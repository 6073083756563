.post-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 20px 40px;
}

.create-post-button {
  background: var(--primary-color);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 auto 30px;
  font-weight: 500;
  transition: background 0.3s ease;
}

.create-post-button:hover {
  background: var(--secondary-color);
}

.post-list {
  list-style: none;
  padding: 0;
}

.post-item {
  background: white;
  border-radius: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.post-item:hover {
  transform: translateY(-2px);
}

.post-link {
  display: block;
  padding: 30px;
  text-decoration: none;
  color: inherit;
}

.post-title {
  font-size: 1.5rem;
  color: var(--text-primary);
  margin-bottom: 15px;
}

.post-excerpt {
  color: var(--text-secondary);
  margin-bottom: 15px;
  line-height: 1.6;
}

.post-hashtags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.post-hashtags span {
  background: #f1f5f9;
  color: var(--text-secondary);
  padding: 4px 12px;
  border-radius: 15px;
  font-size: 0.9rem;
}

.post-meta {
  display: flex;
  justify-content: space-between;
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.post-meta span {
  margin-right: 15px;
}

.no-posts-message {
  text-align: center;
  color: var(--text-secondary);
  padding: 40px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

@media (max-width: 768px) {
  .post-list-container {
    margin: 20px auto;
  }

  .post-link {
    padding: 20px;
  }

  .post-meta {
    flex-direction: column;
    gap: 10px;
  }
}

/* Related Events Section */
.related-events-section {
  margin-bottom: 40px;
  background: white;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.related-events-section h2 {
  font-size: 1.5rem;
  color: var(--text-primary);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.related-events-section h2::before {
  content: '';
  width: 4px;
  height: 24px;
  background: var(--gradient-primary);
  border-radius: 2px;
}

.related-events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}

.related-event-card {
  background: #f8fafc;
  border-radius: 15px;
  padding: 20px;
  transition: transform 0.3s ease;
  text-decoration: none;
  color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.related-event-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.08);
}

.related-event-card h3 {
  font-size: 1.1rem;
  color: var(--text-primary);
  margin-bottom: 12px;
  line-height: 1.4;
}

.related-event-card .event-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.related-event-card .event-date,
.related-event-card .event-duration {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-secondary);
  font-size: 0.9rem;
  padding: 6px 12px;
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.related-event-card i {
  color: var(--primary-color);
  width: 16px;
}

@media (max-width: 768px) {
  .related-events-section {
    padding: 20px;
  }

  .related-events-grid {
    grid-template-columns: 1fr;
  }
}

.post-timestamps {
  font-size: 0.9em;
  color: #666;
  margin-top: 5px;
}

.post-timestamps span {
  margin-right: 20px;
}

.post-timestamps span:last-child {
  margin-right: 0;
}
