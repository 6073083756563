/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.marketplace-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.marketplace-hero {
  text-align: center;
  padding: 60px 40px 40px;
  margin-bottom: 40px;
  border-radius: 20px;
  
  /* Option 1: Subtle Professional Blue (Recommended) */
  /* background: linear-gradient(135deg, 
    #f8fafc 0%,
    #f1f5f9 50%,
    #e2e8f0 100%
  ); */

  background: linear-gradient(135deg,
    #f8fafc 0%,
    #eff6ff 50%,
    #e0f2fe 100%
  );

  /* Option 3: Warm Professional
  background: linear-gradient(135deg,
    #fafaf9 0%,
    #f5f5f4 50%,
    #e7e5e4 100%
  ); */

  /* Option 4: Subtle Multi-tone
  background: linear-gradient(
    135deg,
    #f8fafc 0%,
    #f1f5f9 25%,
    #e2e8f0 50%,
    #cbd5e1 100%
  ); */

  background: linear-gradient(
    135deg,
    #f8fafc 0%,
    #f1f5f9 30%,
    #e2e8f0 70%,
    #cbd5e1 100%
  ),
  linear-gradient(
    45deg,
    transparent 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

/* Optional: Add subtle animation */
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Add this to the hero if you want animation
.marketplace-hero {
  background-size: 200% 200%;
  animation: gradientShift 15s ease infinite;
} */

.marketplace-hero h1 {
  font-size: 3rem;
  color: var(--text-primary);
  margin-bottom: 20px;
}

.hero-subtitle {
  font-size: 1.2rem;
  color: var(--text-secondary);
  max-width: 700px;
  margin: 0 auto 40px;
  line-height: 1.6;
}

.trust-badges {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem 0 0; /* Reduced bottom margin from 2rem to 1rem */
}

.badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  color: var(--text-secondary);
  background: rgba(255, 255, 255, 0.8);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.badge i {
  color: var(--primary-color);
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
}

.product-card {
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-image-container {
  position: relative;
  padding-top: 66.67%;
  overflow: hidden;
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.discount-badge {
  position: absolute;
  top: 20px;
  right: 20px;
  background: var(--primary-color);
  color: white;
  padding: 8px 12px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 0.9rem;
}

.product-info {
  padding: 30px;
}

.product-name {
  font-size: 1.3rem;
  color: var(--text-primary);
  margin-bottom: 15px;
  line-height: 1.4;
}

.product-description {
  color: var(--text-secondary);
  margin-bottom: 20px;
  line-height: 1.6;
}

.price-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.original-price {
  color: var(--text-secondary);
  text-decoration: line-through;
  font-size: 0.9rem;
}

.promotional-price {
  color: var(--primary-color);
  font-size: 1.4rem;
  font-weight: 600;
}

.payment-note {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 20px;
  font-style: italic;
}

.preorder-button {
  width: 100%;
  padding: 12px 24px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.preorder-button:hover {
  background: var(--secondary-color);
}

.preorder-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.modal-content {
  background: white;
  padding: 40px;
  border-radius: 20px;
  max-width: 500px;
  width: 100%;
}

.modal-content h2 {
  margin-bottom: 15px;
  color: var(--text-primary);
  font-size: 1.5rem;
}

.modal-subtitle {
  color: var(--text-secondary);
  margin-bottom: 30px;
  font-size: 1rem;
}

.reservation-benefits {
  background: #f8fafc;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
}

.reservation-benefits p {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  color: var(--text-secondary);
}

.reservation-benefits p i {
  color: #22c55e;
}

.preorder-form .form-group {
  margin-bottom: 20px;
}

.preorder-form input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.preorder-form input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(0, 121, 211, 0.1);
}

.form-actions {
  display: flex;
  gap: 15px;
}

.cancel-button,
.submit-button {
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancel-button {
  background: #f1f5f9;
  border: none;
  color: var(--text-secondary);
}

.submit-button {
  background: var(--primary-color);
  border: none;
  color: white;
}

.cancel-button:hover {
  background: #e2e8f0;
}

.submit-button:hover {
  background: var(--secondary-color);
}

@media (max-width: 768px) {
  .marketplace-hero {
    padding: 60px 20px 40px;
  }

  .marketplace-hero h1 {
    font-size: 2.5rem;
  }

  .trust-badges {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .product-grid {
    grid-template-columns: 1fr;
  }

  .modal-content {
    padding: 30px;
  }
}

.payment-info {
  margin: 15px 0;
  padding: 10px;
  background: #f8fafc;
  border-radius: 8px;
}

.promo-label {
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 5px;
}

.payment-note {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.reservation-benefits {
  background: #f8fafc;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
}

.reservation-benefits p {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
  color: var(--text-secondary);
  font-size: 0.95rem;
}

.reservation-benefits p i {
  color: #22c55e;
}
