.category-list-container {
  max-width: 1200px;
  margin: 60px auto;
  padding: 40px 20px;
}

.category-list-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #0f172a;
  text-align: center;
  margin-bottom: 48px;
  line-height: 1.2;
}

.category-list-subtitle {
  text-align: center;
  color: #64748b;
  font-size: 1.125rem;
  margin-top: -32px;
  margin-bottom: 48px;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.category-card {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.category-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px -8px rgb(0 0 0 / 0.15);
  border-color: #cbd5e1;
}

.category-image {
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.category-image::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.3) 100%);
}

.category-content {
  padding: 24px;
  flex: 1;
}

.category-name {
  font-size: 1.25rem;
  font-weight: 600;
  color: #0f172a;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.category-name i {
  color: #2563eb;
  font-size: 1rem;
}

.category-description {
  color: #64748b;
  font-size: 0.95rem;
  line-height: 1.5;
  margin-bottom: 16px;
}

.category-stats {
  display: flex;
  gap: 16px;
  padding-top: 16px;
  border-top: 1px solid #e2e8f0;
  margin-top: auto;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #64748b;
  font-size: 0.875rem;
}

.stat-item i {
  color: #94a3b8;
  font-size: 0.875rem;
}

.loading-spinner {
  text-align: center;
  padding: 60px 20px;
}

.spinner {
  border: 3px solid #e2e8f0;
  border-top-color: #2563eb;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 16px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .category-list-container {
    padding: 24px 16px;
  }

  .category-list-title {
    font-size: 2rem;
    margin-bottom: 32px;
  }

  .category-list-subtitle {
    font-size: 1rem;
    margin-top: -24px;
    margin-bottom: 32px;
  }

  .category-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

/* Animation for cards */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.category-card {
  animation: fadeInUp 0.5s ease-out;
  animation-fill-mode: both;
}

.category-card:nth-child(1) { animation-delay: 0.1s; }
.category-card:nth-child(2) { animation-delay: 0.2s; }
.category-card:nth-child(3) { animation-delay: 0.3s; }
.category-card:nth-child(4) { animation-delay: 0.4s; }
/* Add more if needed */
