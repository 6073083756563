.post-container {
  max-width: 900px;
  margin: 100px auto 40px; /* Increased top margin to account for navbar */
  padding: 0 20px;
}

.post-header {
  background: white;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.post-category {
  margin-bottom: 15px;
}

.post-category a {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
}

.post-title {
  font-size: 2rem;
  color: var(--text-primary);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.post-meta {
  display: flex;
  gap: 20px;
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.post-meta a {
  color: var(--primary-color);
  text-decoration: none;
}

.post-hashtags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.hashtag {
  background: #f1f5f9;
  color: var(--text-secondary);
  padding: 4px 12px;
  border-radius: 15px;
  font-size: 0.9rem;
}

.post-content {
  background: white;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 20px;
  line-height: 1.6;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.replies-section {
  background: white;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.replies-title {
  font-size: 1.5rem;
  color: var(--text-primary);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.replies-list {
  list-style: none;
  padding: 0;
}

.reply-item {
  padding: 20px;
  border-bottom: 1px solid #e2e8f0;
}

.reply-item:last-child {
  border-bottom: none;
}

.reply-content {
  margin-bottom: 15px;
  line-height: 1.6;
}

.reply-meta {
  display: flex;
  gap: 20px;
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.reply-form {
  background: white;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.reply-input {
  width: 100%;
  min-height: 100px;
  padding: 15px;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  margin-bottom: 15px;
  font-family: inherit;
  resize: vertical;
}

.reply-button {
  background: var(--primary-color);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  transition: background 0.3s ease;
}

.reply-button:hover {
  background: var(--secondary-color);
}

.link-button {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.link-button:hover {
  background: #f1f5f9;
}

.delete-post-button {
  background: #ef4444;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 20px 0;
  font-weight: 500;
  transition: background 0.3s ease;
}

.delete-post-button:hover {
  background: #dc2626;
}

.reply-to-reply-button {
  background: #f1f5f9;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  color: var(--text-secondary);
  font-size: 0.9rem;
  transition: background 0.3s ease;
}

.reply-to-reply-button:hover {
  background: #e2e8f0;
}

.replying-to {
  background: #f1f5f9;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.replying-to button {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  text-decoration: underline;
}

.highlighted {
  animation: highlight 3s ease-out;
}

@keyframes highlight {
  0% { background-color: #fef3c7; }
  100% { background-color: transparent; }
}

@media (max-width: 768px) {
  .post-container {
    margin: 20px auto;
  }

  .post-header {
    padding: 20px;
  }

  .post-title {
    font-size: 1.5rem;
  }

  .post-meta {
    flex-direction: column;
    gap: 10px;
  }

  .replies-section {
    padding: 20px;
  }
}

.nested-replies {
  margin-left: 20px;
  border-left: 2px solid #e0e0e0;
  padding-left: 10px;
}

.reply-item.depth-0 {
  margin-left: 0;
}

.reply-item.depth-1 {
  margin-left: 20px;
}

.reply-item.depth-2 {
  margin-left: 40px;
}

/* Add more depth classes if needed */

.replying-to {
  background: #f1f5f9;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.replying-to button {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  text-decoration: underline;
}

.cancel-reply-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.cancel-reply-button:hover {
  background-color: #c82333;
}

.link-button {
  background: none;
  border: none;
  color: #6e8efb;
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  padding: 0;
  transition: color 0.3s ease;
}

.link-button:hover {
  color: #4a6cf7;
}

.post-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post-title .link-button {
  font-size: 1.2rem;
}

.reply-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
