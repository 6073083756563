@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body {
  font-family: Arial, sans-serif;
  background-color: #DAE0E6;
  margin: 0;
  padding: 0;
}

.recent-activity {
  display: flex;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.recent-posts {
  flex: 2;
}

.sidebar {
  flex: 1;
}

.posts-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 20px;
}

.post-card {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-card h3 {
  margin: 0 0 0.5rem 0;
}

.post-card h3 a {
  color: #1a1a1a;
  text-decoration: none;
}

.post-card h3 a:hover {
  color: #0079D3;
}

.post-meta {
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.post-timestamps {
  display: flex;
  gap: 1rem;
  font-size: 0.8rem;
  color: #888;
}

.sidebar-section {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 3rem;
}

.create-post-btn, .login-btn {
  display: block;
  width: 100%;
  padding: 0.8rem;
  background: #0079D3;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.create-post-btn:hover, .login-btn:hover {
  background: #005fa3;
}

.top-categories {
  list-style: none;
  padding: 0;
  margin: 0;
}

.top-categories li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
}

.top-categories li:last-child {
  border-bottom: none;
}

.top-categories a {
  color: #1a1a1a;
  text-decoration: none;
}

.top-categories a:hover {
  color: #0079D3;
}

.loading {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #666;
}

.error {
  text-align: center;
  padding: 2rem;
  color: #dc3545;
}

@media (max-width: 768px) {
  .recent-activity {
    flex-direction: column;
  }

  .sidebar {
    order: -1;
  }
}
