/* Main Container */
.events-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 20px 40px;
}

/* Featured Events Section */
.featured-events {
  background: linear-gradient(135deg, #f6f9fc 0%, #edf2f7 100%);
  border-radius: 30px;
  padding: 60px 40px;
  margin-bottom: 60px;
  position: relative;
  overflow: hidden;
}

.featured-events::before {
  content: '';
  position: absolute;
  top: -50px;
  right: -50px;
  width: 200px;
  height: 200px;
  background: var(--gradient-primary);
  border-radius: 50px;
  transform: rotate(45deg);
  opacity: 0.1;
}

.featured-events h2 {
  text-align: center;
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 40px;
}

/* Category Events Section */
.category-events {
  margin-bottom: 60px;
}

.category-events h2 {
  font-size: 2rem;
  color: var(--text-primary);
  margin-bottom: 30px;
  position: relative;
  padding-left: 20px;
}

.category-events h2::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 24px;
  background: var(--gradient-primary);
  border-radius: 2px;
}

/* Events Grid */
.events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

/* Event Card */
.event-card {
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0,0,0,0.1);
}

.event-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.event-content {
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.event-header h3 {
  font-size: 1.3rem;
  color: var(--text-primary);
  font-weight: 600;
  flex: 1;
  margin-right: 16px;
}

.event-price {
  background: var(--gradient-primary);
  color: white;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.event-description {
  color: var(--text-secondary);
  font-size: 0.95rem;
  line-height: 1.6;
  margin-bottom: 20px;
  flex-grow: 1;
}

.event-info {
  border-top: 1px solid rgba(0,0,0,0.1);
  padding-top: 20px;
  margin-bottom: 20px;
}

.event-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.event-date,
.event-duration,
.event-location,
.event-capacity {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.event-details i {
  color: var(--primary-color);
  font-size: 1rem;
  width: 16px;
}

/* Event Actions */
.event-actions {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: auto;
}

.view-details-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  background: transparent;
  font-size: 0.9rem;
  gap: 8px;
  height: 38px;
  min-width: fit-content;
}

.view-details-button i {
  font-size: 0.8rem;
  margin-top: 1px;
}

.view-details-button:hover {
  background: rgba(0, 121, 211, 0.1);
  transform: translateY(-2px);
}

.waitlist-button {
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: none;
  border-radius: 8px;
  background: var(--gradient-primary);
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  height: 38px;
}

.waitlist-button.joined {
  background: var(--gradient-secondary);
}

.waitlist-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 121, 211, 0.2);
}

/* Loading State */
.loading {
  text-align: center;
  padding: 40px;
  color: var(--text-secondary);
  font-size: 1.2rem;
}

/* Error State */
.error {
  text-align: center;
  padding: 40px;
  color: #dc3545;
  font-size: 1.2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .events-container {
    margin: 20px auto;
  }

  .featured-events {
    padding: 40px 20px;
    margin-bottom: 40px;
  }

  .featured-events h2 {
    font-size: 2rem;
  }

  .category-events h2 {
    font-size: 1.8rem;
  }

  .events-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .event-details {
    grid-template-columns: 1fr;
  }
  
  .event-header {
    flex-direction: column;
  }
  
  .event-price {
    margin-top: 8px;
  }
}

/* Add these styles at the top of your Events.css */
.section-header {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.section-tag {
  background: rgba(0,121,211,0.1);
  color: var(--primary-color);
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 16px;
}

.section-subtitle {
  color: var(--text-secondary);
  font-size: 1.1rem;
  max-width: 600px;
  margin: 15px auto 0;
  line-height: 1.6;
}

/* Add these styles for the event actions */
.event-actions {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
}

.view-details-button {
  padding: 12px 24px;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  color: var(--primary-color);
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  transition: all 0.3s ease;
  background: transparent;
  font-size: 0.95rem;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  height: 42px;
  line-height: 1;
}

.view-details-button i {
  font-size: 0.9rem;
}

.view-details-button:hover {
  background: rgba(0, 121, 211, 0.1);
  transform: translateY(-2px);
}

.waitlist-button {
  flex: 1;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background: var(--gradient-primary);
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.95rem;
  height: 42px;
  line-height: 1;
}

.waitlist-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 121, 211, 0.2);
}

.waitlist-button.joined {
  background: var(--gradient-secondary);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .event-actions {
    flex-direction: column;
  }
  
  .view-details-button {
    width: 100%;
  }
}
