.user-profile {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
}

.profile-header {
  text-align: center;
  margin-bottom: 2rem;
  padding: 2rem;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  border-radius: 10px;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-avatar {
  width: 100px;
  height: 100px;
  background-color: white;
  color: #6e8efb;
  font-size: 3rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto 1rem;
}

.profile-header h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.profile-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.profile-section {
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.profile-section:hover {
  transform: translateY(-5px);
}

.profile-section h2 {
  font-size: 1.5rem;
  color: #6e8efb;
  margin-bottom: 1rem;
  border-bottom: 2px solid #6e8efb;
  padding-bottom: 0.5rem;
}

.username-form {
  display: flex;
  gap: 1rem;
}

.username-form input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.username-form button {
  background-color: #6e8efb;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.username-form button:hover {
  background-color: #4a6cf7;
}

.user-posts, .user-replies {
  list-style-type: none;
  padding: 0;
}

.user-post-item, .user-reply-item {
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  transition: box-shadow 0.3s ease;
}

.user-post-item:hover, .user-reply-item:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-link, .reply-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.post-link h3, .reply-link h4 {
  color: #6e8efb;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.post-link p, .reply-link p {
  color: #333;
  margin-bottom: 0.5rem;
}

.post-date, .reply-date {
  font-size: 0.8rem;
  color: #6c757d;
  display: block;
}

.no-content {
  color: #6c757d;
  font-style: italic;
}

@media (max-width: 768px) {
  .profile-content {
    grid-template-columns: 1fr;
  }
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;

}

.profile-form input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.profile-form button {
  padding: 10px 15px;
  font-size: 16px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-form button:hover {
  background-color: #3a7bc8;
}

.update-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.update-button:hover {
  background-color: #357abd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.update-button:active {
  background-color: #2c6aa0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transform: translateY(1px);
}

.update-card input {
  width: 100%;
  padding: 10px 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.update-card input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.2);
}

.status {
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.status:hover {
  background-color: rgba(74, 144, 226, 0.1);
}

.status input {
  font-size: 16px;
  padding: 5px;
  border: 1px solid #4a90e2;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
}

.status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.status-label {
  font-weight: bold;
  margin-right: 0.5rem;
}

.status-text {
  margin-right: 0.5rem;
}

.edit-icon {
  font-size: 1rem;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease;
}

.edit-icon:hover {
  color: #f0f0f0;
}

.status-container input {
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
  border: none;
  border-radius: 4px;
  margin-right: 0.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
}
