.stream-list {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

.stream-list h2 {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.create-stream-link {
  display: inline-block;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.create-stream-link:hover {
  background-color: #0056b3;
}

.streams {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.stream-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.stream-item:hover {
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  transform: translateY(-5px);
}

.stream-link {
  display: block;
  color: #333;
  text-decoration: none;
}

.stream-thumbnail {
  width: 100%;
  height: 150px;
  background-color: #ddd;
  background-size: cover;
  background-position: center;
}

.stream-info {
  padding: 15px;
}

.stream-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.stream-meta {
  font-size: 14px;
  color: #666;
}

.stream-streamer {
  font-weight: 500;
}

.stream-category {
  margin-top: 5px;
  font-style: italic;
}

.no-streams {
  text-align: center;
  color: #666;
  font-style: italic;
  grid-column: 1 / -1;
}

