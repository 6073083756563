.footer {
  background: var(--background-light);
  padding: 80px 0 40px;
  margin-top: 80px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-grid {
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr) 2fr;
  gap: 40px;
  margin-bottom: 60px;
}

/* Company Info Section */
.footer-section h3 {
  font-size: 1.5rem;
  color: var(--text-primary);
  margin-bottom: 20px;
}

.company-description {
  color: var(--text-secondary);
  line-height: 1.6;
  margin-bottom: 24px;
}

.social-links {
  display: flex;
  gap: 16px;
}

.social-links a {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.social-links a:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background: var(--primary-color);
  color: white;
}

/* Footer Sections */
.footer-section h4 {
  font-size: 1.1rem;
  color: var(--text-primary);
  margin-bottom: 20px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 12px;
}

.footer-section ul li a {
  color: var(--text-secondary);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: var(--primary-color);
}

/* Newsletter Section */
.newsletter p {
  color: var(--text-secondary);
  margin-bottom: 20px;
  line-height: 1.6;
}

.newsletter-form {
  width: 100%;
}

.input-wrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.newsletter-form input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  background: white;
}

.newsletter-form input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(0, 121, 211, 0.1);
}

.newsletter-form input:disabled {
  background: #f1f5f9;
  cursor: not-allowed;
}

.newsletter-form button {
  padding: 12px 24px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter-form button:hover:not(:disabled) {
  background: var(--secondary-color);
}

.newsletter-form button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.newsletter-form button.submitting {
  background: var(--secondary-color);
}

/* Subscription Message Styles */
.subscription-message {
  margin-top: 10px;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

.subscription-message.success {
  background: #dcfce7;
  color: #166534;
}

.subscription-message.error {
  background: #fee2e2;
  color: #991b1b;
}

.subscription-message i {
  font-size: 1rem;
}

/* Footer Bottom */
.footer-bottom {
  padding-top: 40px;
  border-top: 1px solid #e2e8f0;
}

.footer-legal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.legal-links {
  display: flex;
  gap: 24px;
}

.legal-links a {
  color: var(--text-secondary);
  text-decoration: none;
  transition: color 0.3s ease;
}

.legal-links a:hover {
  color: var(--primary-color);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .footer-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-section:first-child {
    grid-column: 1 / -1;
  }

  .newsletter {
    grid-column: 1 / -1;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 60px 0 30px;
  }

  .footer-grid {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .footer-legal {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }

  .legal-links {
    flex-direction: column;
    gap: 12px;
  }

  .input-wrapper {
    flex-direction: column;
  }

  .newsletter-form button {
    width: 100%;
  }
}
