@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

:root {
  --primary-color: #0079D3;
  --secondary-color: #2A9D8F;
  --accent-color: #E9C46A;
  --text-primary: #2D3748;
  --text-secondary: #4A5568;
  --background-light: #F7FAFC;
  --gradient-primary: linear-gradient(135deg, #0079D3 0%, #00509A 100%);
  --gradient-secondary: linear-gradient(135deg, #2A9D8F 0%, #1A6B63 100%);
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
}

.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Hero Section with geometric shapes */
.hero-section {
  padding: 120px 0 80px;
  background: linear-gradient(135deg, #f6f9fc 0%, #edf2f7 100%);
  border-radius: 30px;
  margin: 40px auto;
  position: relative;
  overflow: hidden;
}

/* Add geometric shapes to hero background */
.hero-section::before {
  content: '';
  position: absolute;
  top: -50px;
  right: -50px;
  width: 200px;
  height: 200px;
  background: var(--gradient-primary);
  border-radius: 50px;
  transform: rotate(45deg);
  opacity: 0.1;
}

.hero-section::after {
  content: '';
  position: absolute;
  bottom: -30px;
  left: -30px;
  width: 150px;
  height: 150px;
  background: var(--gradient-secondary);
  border-radius: 30px;
  transform: rotate(-30deg);
  opacity: 0.1;
}

.hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.hero-section h1 {
  font-size: 4rem;
  line-height: 1.2;
  color: var(--text-primary);
  margin-bottom: 24px;
}

.highlight {
  color: var(--primary-color);
  position: relative;
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: var(--accent-color);
  opacity: 0.3;
  z-index: -1;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: var(--text-secondary);
  margin-bottom: 40px;
  line-height: 1.6;
}

/* Social Proof */
.social-proof {
  margin-top: 60px;
  padding-top: 40px;
  border-top: 1px solid rgba(0,0,0,0.1);
}

.stats {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 60px;
}

.stat-item {
  background: white;
  padding: 20px 30px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.05);
  text-align: center;
  min-width: 150px;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5px;
}

.stat-label {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

/* Features Section */
.section-header {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.section-tag {
  background: rgba(0,121,211,0.1);
  color: var(--primary-color);
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 16px;
  position: relative;
}

.section-tag::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -20px;
  width: 10px;
  height: 2px;
  background: var(--primary-color);
  transform: translateY(-50%);
}

.section-tag::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -20px;
  width: 10px;
  height: 2px;
  background: var(--primary-color);
  transform: translateY(-50%);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.feature-card {
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.feature-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--gradient-primary);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.feature-card:hover::before {
  opacity: 1;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0,0,0,0.1);
}

.feature-card i {
  font-size: 2.5rem;
  color: #0079D3;
  margin-bottom: 20px;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #1a1a1a;
}

.feature-card p {
  color: #666;
  line-height: 1.6;
}

.categories-preview {
  padding: 80px 0;
  background: #f8f9fa;
  border-radius: 20px;
  margin: 40px 0;
}

.categories-preview h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: #1a1a1a;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  padding: 0 20px;
}

.category-item {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.category-item h3 {
  color: #0079D3;
  margin-bottom: 15px;
  font-size: 1.3rem;
}

.category-item p {
  color: #666;
  line-height: 1.6;
}

.join-community {
  text-align: center;
  padding: 80px 20px;
  background: linear-gradient(135deg, #0079D3 0%, #005fa3 100%);
  border-radius: 20px;
  margin: 40px 0;
  color: white;
}

.join-community h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.join-community p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  opacity: 0.9;
}

.cta-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
}

.cta-button {
  padding: 16px 32px;
  border-radius: 12px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.cta-button.primary {
  background: var(--gradient-primary);
  color: white;
  border: none;
}

.cta-button.primary::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255,255,255,0.2), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.cta-button.primary:hover::after {
  opacity: 1;
}

.cta-button.secondary {
  background: white;
  color: var(--primary-color);
  border: 2px solid transparent;
  background-image: linear-gradient(white, white), var(--gradient-primary);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.cta-button.secondary:hover {
  background-color: rgba(0, 121, 211, 0.1);
  transform: translateY(-2px);
}

.join-community .cta-button {
  background-color: white;
  color: #0079D3;
  border: none;
}

.join-community .cta-button:hover {
  background-color: #f0f7ff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-section {
    padding: 80px 20px;
  }

  .hero-section h1 {
    font-size: 2.5rem;
  }

  .features-section h2,
  .categories-preview h2,
  .join-community h2 {
    font-size: 2rem;
  }

  .cta-buttons {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .cta-button {
    width: 100%;
    max-width: 300px;
    text-align: center;
  }

  .stats {
    flex-direction: column;
    align-items: center;
  }

  .stat-item {
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .hero-section h1 {
    font-size: 2rem;
  }

  .hero-section p {
    font-size: 1rem;
  }
}

/* Enhanced Hero Section */
.hero-section {
  padding: 120px 0 80px;
  background: linear-gradient(135deg, #f6f9fc 0%, #edf2f7 100%);
  border-radius: 30px;
  margin: 40px auto;
  position: relative;
  overflow: hidden;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
}

.hero-content h1 {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 24px;
  color: var(--text-primary);
}

.highlight {
  position: relative;
  color: var(--primary-color);
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  height: 8px;
  background: var(--accent-color);
  opacity: 0.3;
  z-index: -1;
}

/* Enhanced Feature Cards */
.feature-card {
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.feature-icon {
  width: 60px;
  height: 60px;
  background: var(--gradient-primary);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  transform: rotate(-5deg);
  transition: transform 0.3s ease;
  position: relative;
}

.feature-icon i {
  font-size: 24px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
}

.feature-card:hover .feature-icon {
  transform: rotate(0deg) scale(1.1);
}

.feature-content h3 {
  font-size: 1.5rem;
  margin-bottom: 16px;
  color: var(--text-primary);
}

.feature-content p {
  color: var(--text-secondary);
  line-height: 1.6;
}

/* Enhanced Topic Cards */
.topic-card {
  background: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.topic-icon {
  width: 50px;
  height: 50px;
  background: var(--background-light);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.topic-description {
  color: var(--text-secondary);
  margin: 12px 0 20px;
  line-height: 1.6;
}

.topic-link {
  margin-top: auto;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: gap 0.3s ease;
}

.topic-link:hover {
  gap: 12px;
}

/* Enhanced Join Section */
.join-section {
  background: var(--gradient-primary);
  border-radius: 30px;
  padding: 80px 40px;
  position: relative;
  overflow: hidden;
  margin: 80px 0;
  text-align: center;
}

.join-content {
  position: relative;
  z-index: 2;
  max-width: 600px;
  margin: 0 auto;
}

.join-section .section-tag {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 24px;
}

.join-section h2 {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 20px;
  line-height: 1.3;
}

.join-section p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  margin-bottom: 40px;
  line-height: 1.6;
}

.join-section .cta-button.primary {
  background: white;
  color: var(--primary-color);
  padding: 16px 32px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  transition: all 0.3s ease;
}

.join-section .cta-button.primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.join-section .cta-button.primary i {
  font-size: 0.9rem;
  transition: transform 0.3s ease;
}

.join-section .cta-button.primary:hover i {
  transform: translateX(4px);
}

/* Background shapes */
.background-shapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.shape {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.shape-1 {
  width: 150px;
  height: 150px;
  top: -75px;
  right: -75px;
}

.shape-2 {
  width: 100px;
  height: 100px;
  bottom: -50px;
  left: -50px;
}

.shape-3 {
  width: 70px;
  height: 70px;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

/* Responsive Design */
@media (max-width: 768px) {
  .join-section {
    padding: 60px 20px;
    margin: 40px 0;
  }

  .join-section h2 {
    font-size: 2rem;
  }

  .join-section p {
    font-size: 1.1rem;
    margin-bottom: 30px;
  }

  .join-section .cta-button.primary {
    width: 100%;
    justify-content: center;
    padding: 14px 24px;
  }
}

@media (max-width: 480px) {
  .join-section h2 {
    font-size: 1.8rem;
  }

  .join-section p {
    font-size: 1rem;
  }
}

/* Enhanced Section Headers */
.section-header {
  text-align: center;
  margin-bottom: 60px;
}

.section-tag {
  background: rgba(0,121,211,0.1);
  color: var(--primary-color);
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 16px;
}

.section-subtitle {
  color: var(--text-secondary);
  font-size: 1.2rem;
  max-width: 600px;
  margin: 16px auto 0;
  line-height: 1.6;
}

/* Enhanced Responsive Design */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }

  .features-grid,
  .topics-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .feature-card,
  .topic-card {
    padding: 25px;
  }

  .join-section {
    padding: 60px 20px;
  }
}

/* Topics Section Styling */
.topics-section {
  padding: 80px 0;
  background: var(--background-light);
  border-radius: 30px;
  margin: 40px 0;
}

.topics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Changed to 2 columns */
  gap: 30px;
  padding: 0 20px;
  max-width: 1000px; /* Limit maximum width */
  margin: 0 auto;
}

.topic-card {
  background: white;
  padding: 35px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.topic-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0,0,0,0.1);
}

.topic-icon {
  width: 50px;
  height: 50px;
  background: var(--background-light);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.topic-icon i {
  font-size: 20px;
  color: var(--primary-color);
}

.topic-card h3 {
  font-size: 1.3rem;
  color: var(--text-primary);
  margin-bottom: 12px;
  font-weight: 600;
}

.topic-description {
  color: var(--text-secondary);
  font-size: 0.95rem;
  line-height: 1.6;
  margin-bottom: 20px;
  flex-grow: 1; /* Makes description take available space */
}

.topic-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  margin-top: auto; /* Pushes link to bottom */
}

.topic-link:hover {
  gap: 12px;
  color: var(--secondary-color);
}

.topic-link i {
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

.topic-link:hover i {
  transform: translateX(3px);
}

/* Enhanced Section Header for Topics */
.topics-section .section-header {
  text-align: center;
  margin-bottom: 50px;
  padding: 0 20px;
}

.topics-section .section-header h2 {
  font-size: 2.5rem;
  color: var(--text-primary);
  margin: 15px 0;
}

.topics-section .section-subtitle {
  color: var(--text-secondary);
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Responsive Design for Topics */
@media (max-width: 968px) {
  .topics-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .topic-card {
    padding: 25px;
  }
}

@media (max-width: 768px) {
  .topics-grid {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto;
  }

  .topics-section .section-header h2 {
    font-size: 2rem;
  }

  .topic-card {
    padding: 30px;
  }
}

/* Add these to your existing topics section styles */
.view-all-container {
  text-align: center;
  margin-top: 40px;
}

.view-all-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  padding: 12px 24px;
  border-radius: 8px;
  transition: all 0.3s ease;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.view-all-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.view-all-link i {
  font-size: 0.9rem;
  transition: transform 0.3s ease;
}

.view-all-link:hover i {
  transform: translateX(4px);
}

/* Update topic-card for consistency with categories */
.topic-card {
  background: white;
  padding: 35px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.topic-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0,0,0,0.1);
}

.topic-icon {
  width: 50px;
  height: 50px;
  background: var(--background-light);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.topic-icon i {
  font-size: 20px;
  color: var(--primary-color);
}

.topic-card h3 {
  font-size: 1.3rem;
  color: var(--text-primary);
  margin-bottom: 12px;
  font-weight: 600;
}

.topic-description {
  color: var(--text-secondary);
  font-size: 0.95rem;
  line-height: 1.6;
  margin-bottom: 20px;
  flex-grow: 1;
}

/* Events Section */
.events-section {
  padding: 80px 0;
  margin-top: 20px;
  background: #f8fafc;
}

.events-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 40px;
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

.event-card {
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,0.05);
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0,0,0,0.1);
}

.event-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.event-content {
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.event-header h3 {
  font-size: 1.3rem;
  color: var(--text-primary);
  font-weight: 600;
  margin: 0;
  line-height: 1.4;
}

.event-price {
  background: var(--gradient-primary);
  color: white;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  white-space: nowrap;
  margin-left: 12px;
}

.event-description {
  color: var(--text-secondary);
  font-size: 0.95rem;
  line-height: 1.6;
  margin-bottom: 20px;
  flex-grow: 1;
}

.event-info {
  border-top: 1px solid rgba(0,0,0,0.08);
  padding-top: 20px;
  margin-bottom: 20px;
}

.event-details {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
}

.event-date,
.event-duration,
.event-location {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.event-details i {
  color: var(--primary-color);
  width: 16px;
}

.event-actions {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: auto;
}

.view-details-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  background: transparent;
  font-size: 0.9rem;
  gap: 8px;
  height: 38px;
  min-width: fit-content;
}

.waitlist-button {
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: none;
  border-radius: 8px;
  background: var(--gradient-primary);
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  height: 38px;
}

.view-all-container {
  text-align: center;
  margin-top: 40px;
}

.view-all-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.view-all-link:hover {
  transform: translateX(5px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .events-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .events-grid {
    grid-template-columns: 1fr;
  }

  .event-actions {
    flex-direction: column;
  }

  .view-details-button {
    width: 100%;
  }
}

.feature-icon svg {
  font-size: 24px;
  color: white;
}

.event-details svg {
  width: 16px;
  color: var(--primary-color);
}

.view-details-button svg {
  font-size: 0.8rem;
}
