.event-details-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 20px 40px;
}

.event-banner {
  background: linear-gradient(135deg, #f6f9fc 0%, #edf2f7 100%);
  border-radius: 30px;
  padding: 80px 40px;
  margin: 0 auto 40px;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.section-tag {
  background: rgba(0,121,211,0.1);
  color: var(--primary-color);
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 16px;
}

.event-detail-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 8px;
}

.event-info {
  background: white;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
}

.event-metadata {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.metadata-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-secondary);
  font-size: 1rem;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
}

.metadata-item i {
  color: var(--primary-color);
}

.waitlist-button {
  width: 100%;
  padding: 16px 32px;
  border: none;
  border-radius: 8px;
  background: var(--gradient-primary);
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.waitlist-button.joined {
  background: var(--gradient-secondary);
}

.waitlist-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 121, 211, 0.2);
}

@media (max-width: 768px) {
  .event-details-container {
    padding-top: 80px;
  }
  
  .event-banner {
    padding: 60px 20px;
  }
  
  .event-info {
    padding: 20px;
  }
}
