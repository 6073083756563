
.legal-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .legal-hero {
    text-align: center;
    padding: 60px 40px;
    background: linear-gradient(135deg, #f6f9fc 0%, #edf2f7 100%);
    border-radius: 20px;
    margin-bottom: 60px;
  }
  
  .legal-hero h1 {
    font-size: 2.8rem;
    color: var(--text-primary);
    margin-bottom: 15px;
  }
  
  .last-updated {
    color: var(--text-secondary);
    font-size: 1rem;
  }
  
  .legal-content {
    display: grid;
    grid-template-columns: 280px 1fr;
    gap: 40px;
    position: relative;
  }
  
  /* Navigation Sidebar */
  .legal-nav {
    position: sticky;
    top: 100px;
    height: fit-content;
    background: white;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .nav-header {
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e2e8f0;
    font-size: 1.1rem;
  }
  
  .legal-nav ul {
    list-style: none;
    padding: 0;
  }
  
  .legal-nav li {
    margin-bottom: 12px;
  }
  
  .legal-nav a {
    color: var(--text-secondary);
    text-decoration: none;
    font-size: 0.95rem;
    transition: color 0.3s ease;
    display: block;
    padding: 8px 12px;
    border-radius: 6px;
  }
  
  .legal-nav a:hover {
    color: var(--primary-color);
    background: #f8fafc;
  }
  
  /* Content Sections */
  .legal-sections {
    background: white;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .legal-sections section {
    margin-bottom: 50px;
    padding-bottom: 40px;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .legal-sections section:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  
  .legal-sections h2 {
    font-size: 1.8rem;
    color: var(--text-primary);
    margin-bottom: 25px;
    padding-top: 10px;
  }
  
  .legal-sections p {
    color: var(--text-secondary);
    line-height: 1.7;
    margin-bottom: 20px;
    font-size: 1rem;
  }
  
  /* Info Grid */
  .info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin: 30px 0;
  }
  
  .info-card {
    background: #f8fafc;
    padding: 25px;
    border-radius: 12px;
    text-align: center;
  }
  
  .info-card i {
    font-size: 1.8rem;
    color: var(--primary-color);
    margin-bottom: 15px;
  }
  
  .info-card h3 {
    margin-bottom: 12px;
    color: var(--text-primary);
    font-size: 1.1rem;
  }
  
  .info-card p {
    font-size: 0.95rem;
    margin-bottom: 0;
  }
  
  /* Legal List */
  .legal-list {
    list-style: none;
    padding: 0;
    margin: 25px 0;
  }
  
  .legal-list li {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 15px;
    padding: 15px;
    background: #f8fafc;
    border-radius: 8px;
  }
  
  .legal-list i {
    color: var(--primary-color);
    font-size: 1rem;
  }
  
  .legal-list span {
    color: var(--text-secondary);
    font-size: 0.95rem;
  }
  
  /* Contact Info */
  .contact-info {
    margin-top: 25px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 20px;
    background: #f8fafc;
    border-radius: 12px;
    width: fit-content;
  }
  
  .contact-item i {
    color: var(--primary-color);
    font-size: 1.2rem;
  }
  
  .contact-item p {
    margin-bottom: 0;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .legal-content {
      grid-template-columns: 1fr;
    }
  
    .legal-nav {
      position: static;
      margin-bottom: 30px;
    }
  }
  
  @media (max-width: 768px) {
    .legal-hero {
      padding: 40px 20px;
    }
  
    .legal-hero h1 {
      font-size: 2.2rem;
    }
  
    .legal-sections {
      padding: 25px;
    }
  
    .legal-sections h2 {
      font-size: 1.6rem;
    }
  
    .info-grid {
      grid-template-columns: 1fr;
    }
  }