.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .contact-hero {
    text-align: center;
    padding: 80px 40px;
    background: linear-gradient(135deg, #f6f9fc 0%, #edf2f7 100%);
    border-radius: 20px;
    margin-bottom: 60px;
  }
  
  .contact-hero h1 {
    font-size: 3rem;
    color: var(--text-primary);
    margin-bottom: 20px;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    color: var(--text-secondary);
  }
  
  .contact-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 60px;
  }
  
  /* Info Cards */
  .contact-info {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .info-card {
    background: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .info-card:hover {
    transform: translateY(-5px);
  }
  
  .info-card i {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .info-card h3 {
    font-size: 1.2rem;
    color: var(--text-primary);
    margin-bottom: 15px;
  }
  
  .info-card p {
    color: var(--text-secondary);
    line-height: 1.6;
    margin-bottom: 5px;
  }
  
  /* Contact Form */
  .contact-form-container {
    background: white;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  }
  
  .contact-form-container h2 {
    font-size: 1.8rem;
    color: var(--text-primary);
    margin-bottom: 30px;
  }
  
  .form-group {
    margin-bottom: 25px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    color: var(--text-primary);
    font-weight: 500;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(0, 121, 211, 0.1);
  }
  
  .submit-button {
    width: 100%;
    padding: 14px 24px;
    background: var(--primary-color);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .submit-button:hover:not(:disabled) {
    background: var(--secondary-color);
  }
  
  .submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Status Messages */
  .message {
    margin-bottom: 20px;
    padding: 12px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .message.success {
    background: #dcfce7;
    color: #166534;
  }
  
  .message.error {
    background: #fee2e2;
    color: #991b1b;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .contact-content {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  
    .contact-info {
      flex-direction: row;
      flex-wrap: wrap;
    }
  
    .info-card {
      flex: 1;
      min-width: 250px;
    }
  }
  
  @media (max-width: 768px) {
    .contact-hero {
      padding: 60px 20px;
    }
  
    .contact-hero h1 {
      font-size: 2.5rem;
    }
  
    .contact-form-container {
      padding: 30px 20px;
    }
  
    .info-card {
      min-width: 100%;
    }
  }