.create-stream {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .create-stream h2 {
    margin-bottom: 20px;
  }
  
  .create-stream form > div {
    margin-bottom: 15px;
  }
  
  .create-stream label {
    display: block;
    margin-bottom: 5px;
  }
  
  .create-stream input,
  .create-stream textarea,
  .create-stream select {
    width: 100%;
    padding: 8px;
  }
  
  .create-stream button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }