.main-navigation {
  background-color: #f8f9fa;
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.main-navigation ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.main-navigation li {
  margin: 0 1rem;
}

.main-navigation a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: color 0.3s ease;
}

.main-navigation a:hover {
  color: #007bff;
}

.logout-button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: inherit;
  font-weight: 500;
  padding: 0;
  transition: color 0.3s ease;
}

.logout-button:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  .main-navigation ul {
    flex-direction: column;
    align-items: center;
  }

  .main-navigation li {
    margin: 0.5rem 0;
  }
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--navbar-height);
  z-index: 1000;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.navbar.scrolled {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Logo */
.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 12px;
}

.logo-image {
  height: 40px;
  width: auto;
}

.site-name {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
}

/* Navigation Menu */
.nav-menu {
  display: flex;
  align-items: center;
  gap: 40px;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: nowrap;
}

.nav-link {
  color: var(--text-secondary);
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  padding: 8px 12px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}

.nav-link.active {
  color: var(--primary-color);
  background-color: rgba(var(--primary-color-rgb), 0.1);
  border-radius: 8px;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--primary-color);
  transition: width 0.3s ease;
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
}

/* Dropdowns */
.nav-dropdown {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 12px;
  padding: 8px 0;
  min-width: 200px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
}

.nav-dropdown:hover .dropdown-content,
.user-dropdown:hover .dropdown-content {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-content a,
.dropdown-content button {
  display: block;
  width: 100%;
  padding: 12px 20px;
  color: var(--text-secondary);
  text-decoration: none;
  background: none;
  border: none;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dropdown-content a:hover,
.dropdown-content button:hover {
  background: var(--background-light);
  color: var(--primary-color);
}

/* Auth Buttons */
.nav-actions {
  display: flex;
  align-items: center;
  height: 40px;
}

.auth-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
}

.login-button,
.signup-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.9rem;
  text-decoration: none;
  transition: all 0.3s ease;
  white-space: nowrap;
  min-width: 100px;
  box-sizing: border-box;
  line-height: 1;
}

.login-button {
  color: var(--primary-color);
  background: transparent;
  border: 2px solid var(--primary-color);
}

.signup-button {
  color: white;
  background: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.login-button:not(:hover),
.signup-button:not(:hover) {
  margin: 0;
  transform: none;
}

.login-button:hover,
.signup-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.signup-button:hover {
  background: var(--primary-color-dark, #0056b3);
  border-color: var(--primary-color-dark, #0056b3);
}

.login-button:hover {
  background: rgba(var(--primary-color-rgb), 0.1);
}

/* Update mobile styles for auth buttons */
@media (max-width: 1024px) {
  .nav-actions {
    width: 100%;
    height: auto;
  }

  .auth-buttons {
    width: 100%;
    justify-content: center;
    padding: 16px 0;
    height: auto;
  }

  .login-button,
  .signup-button {
    height: 44px;
    padding: 0 24px;
    font-size: 1rem;
    flex: 1;
    max-width: 160px;
  }
}

/* User Menu */
.user-dropdown {
  position: relative;
}

.user-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

/* Mobile Menu */
.mobile-menu-button {
  display: none;
  flex-direction: column;
  gap: 6px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.mobile-menu-button span {
  display: block;
  width: 24px;
  height: 2px;
  background: var(--text-primary);
  transition: all 0.3s ease;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .nav-menu {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    background: white;
    padding: 20px;
    flex-direction: column;
    gap: 20px;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }

  .nav-menu.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }

  .nav-links {
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  .nav-link {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
  }

  .nav-link:hover {
    background-color: var(--background-light);
  }

  .nav-link::after {
    display: none;
  }

  .nav-actions {
    width: 100%;
  }

  .auth-buttons {
    width: 100%;
    justify-content: center;
  }

  .mobile-menu-button {
    display: flex;
  }

  .mobile-menu-button.active span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .mobile-menu-button.active span:nth-child(2) {
    opacity: 0;
  }

  .mobile-menu-button.active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .dropdown-content {
    position: static;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
    transform: none;
    padding-left: 20px;
  }
}

/* Update the logout-button styles */
.logout-button {
  font-family: inherit;
  font-weight: inherit;
}

@media (min-width: 1025px) {
  .nav-link:hover {
    color: var(--primary-color);
    background-color: rgba(var(--primary-color-rgb), 0.05);
    border-radius: 8px;
  }
}

/* Add these variables to your root if not already present */
:root {
  --primary-color: #007bff;
  --primary-color-rgb: 0, 123, 255;
  --primary-color-dark: #0056b3;
}
