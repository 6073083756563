.privacy-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.privacy-hero {
  text-align: center;
  padding: 60px 40px;
  background: linear-gradient(135deg, #f6f9fc 0%, #edf2f7 100%);
  border-radius: 20px;
  margin-bottom: 60px;
}

.privacy-hero h1 {
  font-size: 2.8rem;
  color: var(--text-primary);
  margin-bottom: 15px;
}

.last-updated {
  color: var(--text-secondary);
  font-size: 1rem;
}

.privacy-content {
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 40px;
  position: relative;
}

/* Navigation Sidebar */
.privacy-nav {
  position: sticky;
  top: 100px;
  height: fit-content;
  background: white;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.nav-header {
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e2e8f0;
  font-size: 1.1rem;
}

.privacy-nav ul {
  list-style: none;
  padding: 0;
}

.privacy-nav li {
  margin-bottom: 12px;
}

.privacy-nav a {
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 0.95rem;
  transition: color 0.3s ease;
  display: block;
  padding: 8px 12px;
  border-radius: 6px;
}

.privacy-nav a:hover {
  color: var(--primary-color);
  background: #f8fafc;
}

/* Content Sections */
.privacy-sections {
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.privacy-sections section {
  margin-bottom: 50px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e8f0;
}

.privacy-sections section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.privacy-sections h2 {
  font-size: 1.8rem;
  color: var(--text-primary);
  margin-bottom: 25px;
  padding-top: 10px;
}

.privacy-sections p {
  color: var(--text-secondary);
  line-height: 1.7;
  margin-bottom: 20px;
  font-size: 1rem;
}

/* Information Grid */
.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin: 30px 0;
}

.info-card {
  background: #f8fafc;
  padding: 25px;
  border-radius: 12px;
  text-align: center;
}

.info-card i {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.info-card h3 {
  margin-bottom: 12px;
  color: var(--text-primary);
  font-size: 1.1rem;
}

.info-card p {
  font-size: 0.95rem;
  margin-bottom: 0;
}

/* Usage List */
.usage-list {
  list-style: none;
  padding: 0;
  margin: 25px 0;
}

.usage-list li {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
  padding: 15px;
  background: #f8fafc;
  border-radius: 8px;
}

.usage-list i {
  color: var(--primary-color);
  font-size: 1rem;
}

.usage-list span {
  color: var(--text-secondary);
  font-size: 0.95rem;
}

/* Rights Container */
.rights-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 25px;
}

.right-item {
  text-align: center;
  padding: 25px;
  background: #f8fafc;
  border-radius: 12px;
}

.right-item i {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.right-item h3 {
  margin-bottom: 12px;
  color: var(--text-primary);
  font-size: 1.1rem;
}

.right-item p {
  font-size: 0.95rem;
  margin-bottom: 0;
}

/* Contact Info */
.contact-info {
  margin-top: 25px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px;
  background: #f8fafc;
  border-radius: 12px;
  width: fit-content;
}

.contact-item i {
  color: var(--primary-color);
  font-size: 1.2rem;
}

.contact-item p {
  margin-bottom: 0;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .privacy-content {
    grid-template-columns: 1fr;
  }

  .privacy-nav {
    position: static;
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .privacy-hero {
    padding: 40px 20px;
  }

  .privacy-hero h1 {
    font-size: 2.2rem;
  }

  .privacy-sections {
    padding: 25px;
  }

  .privacy-sections h2 {
    font-size: 1.6rem;
  }

  .info-grid,
  .rights-container {
    grid-template-columns: 1fr;
  }
}
