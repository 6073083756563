.community-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.categories-section {
  margin-bottom: 40px;
}

.recent-activity-section {
  margin-top: 40px;
}

