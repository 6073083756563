.page-banner {
  background: linear-gradient(135deg, #f6f9fc 0%, #edf2f7 100%);
  border-radius: 30px;
  padding: 80px 40px;
  margin: 40px auto;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.page-banner::before {
  content: '';
  position: absolute;
  top: -50px;
  right: -50px;
  width: 200px;
  height: 200px;
  background: var(--gradient-primary);
  border-radius: 50px;
  transform: rotate(45deg);
  opacity: 0.1;
}

.page-banner::after {
  content: '';
  position: absolute;
  bottom: -30px;
  left: -30px;
  width: 150px;
  height: 150px;
  background: var(--gradient-secondary);
  border-radius: 30px;
  transform: rotate(-30deg);
  opacity: 0.1;
}

.banner-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
}

.banner-content h1 {
  font-size: 3rem;
  color: var(--text-primary);
  margin: 20px 0;
  line-height: 1.2;
}

.section-tag {
  background: rgba(0,121,211,0.1);
  color: var(--primary-color);
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 16px;
}

.section-subtitle {
  color: var(--text-secondary);
  font-size: 1.2rem;
  max-width: 600px;
  margin: 15px auto 0;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .page-banner {
    padding: 60px 20px;
    margin: 20px auto;
  }

  .banner-content h1 {
    font-size: 2.5rem;
  }
}
