.stream {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .stream-info {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .stream-video {
    width: 70%;
    margin-right: 5%;
  }
  
  .video-placeholder {
    background-color: #000;
    color: #fff;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .chat-section {
    width: 25%;
  }
  
  .messages-list {
    height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .message {
    margin-bottom: 5px;
  }
  
  .message-author {
    font-weight: bold;
  }
  
  .chat-section form {
    display: flex;
  }
  
  .chat-section input {
    flex-grow: 1;
    padding: 5px;
  }
  
  .chat-section button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }