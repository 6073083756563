/* Reset container padding */
.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px 0; /* Increased top padding */
}

/* Hero section */
.about-hero {
  text-align: center;
  padding: 80px 40px;
  background: linear-gradient(135deg, #f6f9fc 0%, #edf2f7 100%);
  border-radius: 20px;
  margin-bottom: 80px;
  margin-top: 20px; /* Added margin-top */
}

.about-hero h1 {
  font-size: 3.5rem;
  color: var(--text-primary);
  margin-bottom: 20px;
  line-height: 1.2;
}

.about-hero .subtitle {
  font-size: 1.4rem;
  color: var(--text-secondary);
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Mission and Story sections */
.about-mission,
.about-story {
  padding: 60px 0;
}

.mission-content,
.story-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
}

.mission-content h2,
.story-content h2,
.about-values h2 {
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 30px;
}

.mission-content p,
.story-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--text-secondary);
}

/* Values section */
.about-values {
  padding: 80px 20px;
  background: var(--background-light);
  border-radius: 20px;
  margin: 60px 0;
  text-align: center;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  padding: 40px 0;
  max-width: 1100px;
  margin: 0 auto;
}

.value-card {
  background: white;
  padding: 40px 30px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.value-card:hover {
  transform: translateY(-5px);
}

.value-card i {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 25px;
}

.value-card h3 {
  font-size: 1.3rem;
  color: var(--text-primary);
  margin-bottom: 15px;
}

.value-card p {
  color: var(--text-secondary);
  line-height: 1.6;
  font-size: 1rem;
}

/* Join section */
.about-join {
  text-align: center;
  padding: 80px 40px;
  background: var(--gradient-primary);
  color: white;
  border-radius: 20px;
  margin: 60px 0;
}

.about-join h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-join p {
  font-size: 1.2rem;
  margin-bottom: 40px;
  opacity: 0.9;
}

.join-button {
  padding: 16px 36px;
  background: white;
  color: var(--primary-color);
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.join-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-container {
    margin-top: 20px;
  }

  .about-hero {
    padding: 60px 20px;
    margin-bottom: 60px;
  }

  .about-hero h1 {
    font-size: 2.5rem;
  }

  .about-hero .subtitle {
    font-size: 1.2rem;
  }

  .mission-content h2,
  .story-content h2,
  .about-values h2,
  .about-join h2 {
    font-size: 2rem;
  }

  .values-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .about-values,
  .about-join {
    padding: 60px 20px;
    margin: 40px 0;
  }

  .value-card {
    padding: 30px 20px;
  }
}
